// import React, { useEffect, useState } from 'react'
// import axios from 'axios'
// import Pagination from '../pagination/Pagination'
// import { Link } from 'react-router-dom'
// import { FaUsers } from "react-icons/fa6";

// const RegisterUsers=()=>{

//     const [Register, setRegister] = useState([]);
//     const[showPerPage,setShowPerPage]=useState(10)
   

//     const [pagination,setPagination]=useState(
//       {
//         start:0,
//         end:showPerPage
//       }
//     )
   
//     const onChangePagination=(start,end)=>{
//       setPagination({start:start,end:end})
//     }






//     const registerUsersData = async () => {
//       try {
//         const response = await axios.get('https://lions320dapi.emedha.in/register-users');
//         setRegister(response.data || []);
//         console.log(response.data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
  
//     useEffect(() => {
//       registerUsersData();
//     }, []);
    


// return(

//     <>
//     <h4 style={{color:'black'}}>Register Users <FaUsers style={{color:'rgb(245, 170, 30)'}}/></h4>

//     <div style={{marginTop:'1rem'}} className='table-container'>
        
//         <table className="styled-table table table-bordered">
//          <thead>
//            <tr>
//              <th>S.No</th>
//              <th>Mobile</th>
//              <th>Membership No</th>
//              <th>clubName Name</th>
//             <th>Password</th>
//            </tr>
           
//          </thead>
//          <tbody className='table-bordered'>
//            {
//              Register.slice(pagination.start,pagination.end).map((data,index)=>{
//                return(
//                  <tr>
//                    <td>{index+1}</td>
//                    <td>{data.mobile}</td>
//                    <td>{data.membershipNo}</td>
//                    <td>{data.clubName}</td>
//                   <td>{data.password}</td>
//                  </tr>
//                )
//              })
//            }
//          </tbody>
//         </table>
//        <Pagination page={showPerPage} onChangePagination={onChangePagination} total={Register.length}/>      
//    </div>

//    </>
// )
// }

// export default RegisterUsers



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pagination from '../pagination/Pagination';
import { FaUsers } from "react-icons/fa6";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";

const RegisterUsers = () => {
    const [Register, setRegister] = useState([]);
    const [showPerPage, setShowPerPage] = useState(10);
    const [pagination, setPagination] = useState({ start: 0, end: showPerPage });
    const [searchTerm, setSearchTerm] = useState("");
    const [editPasswordId, setEditPasswordId] = useState(null);
    const [newPassword, setNewPassword] = useState("");
    const [modalVisible, setModalVisible] = useState(false);

    const onChangePagination = (start, end) => {
        setPagination({ start, end });
    };


    // https://lions320dapi.emedha.in/register-users

    const registerUsersData = async () => {
        try {
            const response = await axios.get('https://lions320dapi.emedha.in/register-users');
            setRegister(response.data || []);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        registerUsersData();
    }, []);

    console.log(Register)

    // Filtered data based on search term
    const filteredData = Register.filter((data) => {
        return (
            data.mobile.toLowerCase().includes(searchTerm.toLowerCase()) ||
            data.membershipNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
            data.clubName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    // Show modal and set user id for editing password
    const handleEditPassword = (id) => {
        setEditPasswordId(id);
        setModalVisible(true);
        setNewPassword(""); // Clear any old password from the input field
    };

    // Close modal without saving
    const closeModal = () => {
        setModalVisible(false);
        setNewPassword(""); // Clear input when modal closes
    };

    // Save updated password
    const handleSavePassword = async () => {
        try {
            await axios.put(`https://lions320dapi.emedha.in/update-password/${editPasswordId}`, { password: newPassword });
            closeModal(); // Close the modal after saving
            registerUsersData(); // Reload data after password update
            alert("User Password Updated Successfully")
        } catch (error) {
            console.error("Error updating password:", error);
        }
    };

    // Delete user
    const handleDelete = async (id) => {
      console.log(id)
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (confirmDelete) {
            try {
                await axios.delete(`https://lions320dapi.emedha.in/delete-user/${id}`);
                registerUsersData(); // Reload data after deletion
            } catch (error) {
                console.error("Error deleting user:", error);
            }
        }
    };

    return (
        <>
           

            {/* Search Bar */}
            <div style={{ marginBottom: '1rem', display:'flex', flexDirection:'row', justifyContent:'space-between' }}>
            <h4 style={{ color: 'black' }}>
                Register Users <FaUsers style={{ color: 'rgb(245, 170, 30)' }} />
            </h4>
                <input
                    type="text"
                    placeholder="Search by Mobile, Membership No, or Club Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ padding: '5px', width: '50%' }}
                />
            </div>

            <div style={{ marginTop: '1rem' }} className='table-container'>
                <table className="styled-table table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Mobile</th>
                            <th>Membership No</th>
                            <th>Club Name</th>
                            <th>Password</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className='table-bordered'>
                        {
                            filteredData.slice(pagination.start, pagination.end).map((data, index) => {
                                return (
                                    <tr key={data.id}>
                                        <td>{pagination.start + index + 1}</td>
                                        <td>{data.mobile}</td>
                                        <td>{data.membershipNo}</td>
                                        <td>{data.clubName}</td>
                                        <td>{data.password}</td>
                                        <td>
                                            <button
                                                className="btn btn-info"
                                                onClick={() => handleEditPassword(data.id)}
                                            >
                                                <BsFillPencilFill />
                                            </button>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => handleDelete(data.id)}
                                                style={{marginLeft:12}}
                                            >
                                                <BsFillTrashFill />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <Pagination page={showPerPage} onChangePagination={onChangePagination} total={filteredData.length} />
            </div>

            {/* Modal for editing password */}
            {modalVisible && (
                <div style={modalStyles.overlay}>
                    <div style={modalStyles.modal}>
                        <h4>Edit Password</h4>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Enter new password"
                            style={modalStyles.input}
                        />
                        <div style={modalStyles.buttons}>
                            <button onClick={handleSavePassword} style={modalStyles.updateButton}>Update</button>
                            <button onClick={closeModal} style={modalStyles.cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '300px',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        border: '1px solid #ddd',
        borderRadius: '4px',
    },
    buttons: {
        display: 'flex',
    alignItems:'center'
    },
    updateButton: {
        padding: '10px 20px',
        backgroundColor: '#28a745',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginRight:20
    },
    cancelButton: {
        padding: '10px 20px',
        backgroundColor: '#dc3545',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default RegisterUsers;
