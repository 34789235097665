import React, { useEffect, useState } from 'react';
import './Gallery.css'; // Import CSS file for styling

const Gallery = () => {
  const [images, setImages] = useState([]);


    const fetchImages = async () => {
      try {
        const response = await fetch('https://lions320dapi.emedha.in/gallery/not-approved');
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };


    useEffect(() => {

    fetchImages();
  }, []);

  const handleApprove = async (id) => {
    try {
        const response = await fetch(`https://lions320dapi.emedha.in/approve/${id}`, { method: 'POST' });
        if (response.ok) {
            // Optionally, refresh the gallery or update the state
            console.log(`Image ${id} approved.`);
            alert(`Image Successfully Approved.`);
            fetchImages();
        } else {
            console.error('Error approving image:', await response.json());
        }
    } catch (error) {
        console.error('Error approving image:', error);
    }
};

const handleDelete = async (id) => {
    try {
        const response = await fetch(`https://lions320dapi.emedha.in/delete/${id}`, { method: 'DELETE' });
        if (response.ok) {
            // Optionally, refresh the gallery or update the state
            console.log(`Image ${id} deleted.`);
            alert(`Image successfully deleted.`);
            fetchImages();
        } else {
            console.error('Error deleting image:', await response.json());
        }
    } catch (error) {
        console.error('Error deleting image:', error);
    }
};


  return (
    <div className="gallery-container">
      <h1 style={{color: "rgb(245, 170, 30)" }}>Image Gallery</h1>
      <div className="gallery-grid">
        {images.map((image) => (
          <div key={image.id} className="gallery-item">
            <img src={image.image} alt={`Image ${image.id}`} className="gallery-image" />
            <div className="button-container">
              <button className="approve-button" onClick={() => handleApprove(image.id)}>
                Approve
              </button>
              <button className="delete-button" onClick={() => handleDelete(image.id)}>
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
