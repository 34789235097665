// import React, { useEffect, useState } from 'react'
// import axios from 'axios'
// import Pagination from '../pagination/Pagination'
// import { Form, Link } from 'react-router-dom'
// import moment from 'moment'
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
// import { Container } from '@mui/system'
// import { FaSearch } from 'react-icons/fa';
// import { MDBBtn, MDBCollapse, MDBContainer, MDBFile, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit'
// import Swal from 'sweetalert2';
// import './index.css'



// const Member = () => {

//   const [member, setMember] = useState([])
//   const [showPerPage] = useState(5)
//   const [pagination, setPagination] = useState(
//     {
//       start: 0,
//       end: showPerPage
//     }
//   )

//   const onChangePagination = (start, end) => {
//     setPagination({ start: start, end: end })
//   }

//   const memberData = async () => {
//     const res = await axios.get(`https://lions320dapi.emedha.in/memberMasteData`)
//     setMember(res.data)
//     console.log(res.data)
//   }

//   const [memberClubName, setMemberClubName] = useState([])

//   const memberDataClubName = async () => {
//     const res = await axios.get(`https://lions320dapi.emedha.in/clubData`)
//     setMemberClubName(res.data)
//   }

//   useEffect(() => {
//     memberData()
//     memberDataClubName()
//   }, [])

//   const [number, setMemberNumber] = useState('')
//   const [memberName, setMemberName] = useState('')
//   const [clubName, setClubName] = useState('')
//   const [designation, setDesignation] = useState('')
//   const [profession, setProfession] = useState('')
//   const [photo, setPhoto] = useState('')
//   const [birth, setBirth] = useState('')
//   const [marriage, setMarriage] = useState('')
//   const [blood, setBlood] = useState('')
//   const [address, setAddress] = useState('')
//   const [mobile, setMobile] = useState('')
//   const [email, setEmail] = useState('')
//   const [spouseName, setSpouse] = useState('')
//   const [myMessage, setMessage] = useState('')
//   const [search, setSearch] = useState()


//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const handleCloseBulk=()=>setIsOpen1(false)


//   const submitData = (e) => {
//     e.preventDefault();
//     axios.post('https://lions320dapi.emedha.in/memberCreate', {
//       number,
//       memberName,
//       clubName,
//       designation,
//       profession,
//       photo,
//       birth,
//       marriage,
//       blood,
//       address,
//       mobile,
//       email,
//       spouseName
//     }).then(() => {
//       alert('Data Successfully Inserted!')
//       setShow(false)
//       setTimeout(() => memberData(), 100)
//     }).catch((err) => alert(err.res.data))

//   }


//   const deleteData = (id) => {
//     if (window.confirm('Are you sure to delete this data...')) {
//       axios.delete(`https://lions320dapi.emedha.in/deleteMember/${id}`)
//       alert('Data deleted successfully!...')
//       setTimeout(() => memberData(), 100)
//     }
//   }

//   const searchHandler = (e) => {
//     setSearch(e.target.value)

//     axios.post('https://lions320dapi.emedha.in/search', {
//       search
//     }).then((resp) => {
//       setMember(resp.data)
//     })
//   }

//   // const[bfile,setBfile] = useState('')
//   // const bulkuploadhandle = (e) => {
//   //   e.preventDefault()
//   //   const data = new FormData()
//   //   data.append("excel", bfile)
//   //   axios.post('http://localhost:3012/addmemberbulk',data)
//   //   .then((res) => {
//   //     if (res.data.status === 200) {
//   //         toggleOpen1();
//   //         Swal.fire({
//   //             icon: 'success',
//   //             title: 'Success',
//   //             text: 'Data submitted successfully!',
//   //         }).then(() => {
//   //           window.location.reload();
//   //       });
//   //     }
//   // })
//   // .catch((error) => {
//   //     console.error('Error:', error);
//   // });
//   // }


//   const [isOpen1, setIsOpen1] = React.useState(false);
//   const toggleOpen1 = () => setIsOpen1(!isOpen1);
//   const [bfile, setBfile] = useState(null);


//     const handleFileChange = (e) => {
//       console.log('Selected File:', e.target.files[0]);
//       setBfile(e.target.files[0]);
//     };
    


//   const bulkuploadhandle = async (e) => {
//     e.preventDefault(); // Prevent default form submission

//     if (!bfile) {
//       Swal.fire({
//         icon: 'error',
//         title: 'File Required',
//         text: 'Please select a file to upload.',
//       });
//       return;
//     }

//     try {
//       const formData = new FormData();
//       formData.append('excel', bfile);

//       const response = await axios.post('http://localhost:3012/addmemberbulk', formData);

//       if (response.data.status === 200) {
//         toggleOpen1();
//         Swal.fire({
//           icon: 'success',
//           title: 'Success',
//           text: 'Data submitted successfully!',
//           customClass: {
//             confirmButton: 'custom-swal-button',
//           }
//         }).then(() => {
//           window.location.reload(); // Reload the page after success
//         });
//       } else {
//         Swal.fire({
//           icon: 'error',
//           title: 'Oops...',
//           text: 'Something went wrong!',
//         });
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: 'Something went wrong!',
//       });
//     }
//   };

//   return (

//     <>
//       <Container><h5 className='text-center text-danger'>{myMessage}</h5></Container>
//       <Button variant="primary" onClick={handleShow}>
//         Add<PersonAddAltIcon></PersonAddAltIcon>
//       </Button>
//       <Button rounded className='mx-3' color='secondary'  onClick={toggleOpen1}>
//         Bulk upload
//       </Button>
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <br /><br /><br /><br /><br />
//           <Modal.Title>Add</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <form onSubmit={submitData}>
//             <input type='number' className='form-control' placeholder='Membership Number' required onChange={(e) => setMemberNumber(e.target.value)} />
//             <br />
//             <input type='text' className='form-control' placeholder='Membership Name' required onChange={(e) => setMemberName(e.target.value)} />
//             <br />
//             <select type='text' className='form-control' placeholder='Designation' onChange={(e) => setClubName(e.target.value)}>
//               <option>Select Club Name</option>
//               {
//                 memberClubName.map((data) => {
//                   return (
//                     <option value={data.clubName}>
//                       {data.clubName}
//                     </option>
//                   )
//                 })
//               }
//             </select>
//             <br />
//             <input type='text' className='form-control' placeholder='Designation' required onChange={(e) => setDesignation(e.target.value)} />
//             <br />

//             <input type='text' className='form-control' placeholder='Mobile' onChange={(e) => setMobile(e.target.value)} />
//             <br />
//             <input type='text' className='form-control' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
//             <br />
//             <input type='text' className='form-control' placeholder='Spouse' onChange={(e) => setSpouse(e.target.value)} />
//             <br />
//             <input type='text' className='form-control' placeholder='Profession' onChange={(e) => setProfession(e.target.value)} />
//             <br />
//             <input type='file' className='form-control' placeholder='choose file...' onChange={(e) => setPhoto(e.target.value)} />
//             <br />
//             <input type='date' className='form-control' placeholder='Birth Anniversary' onChange={(e) => setBirth(e.target.value)} />
//             <br />
//             <input type='date' className='form-control' placeholder='Marriage Anniversary' onChange={(e) => setMarriage(e.target.value)} />
//             <br />

//             <input type='text' className='form-control' placeholder='Blood Group' onChange={(e) => setBlood(e.target.value)} />
//             <br />
//             <input type='text' className='form-control' placeholder='Address' onChange={(e) => setAddress(e.target.value)} />
//             <br />
//             <button type='submit' className='btn btn-danger form-control'>Submit</button>
//             <br />
//             <br />
//             <button type='reset' className='btn btn-secondary form-control'>Reset</button>
//           </form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <FaSearch className='searchIcon'></FaSearch><input type='search' className='ms-4 search-input-field' placeholder='Search..' onChange={searchHandler} />
     
      

// {/* <Modal show={isOpen1} onHide={handleCloseBulk} size='md'>
//         <Modal.Header closeButton>
//           <br /><br /><br /><br /><br />
          
//         </Modal.Header>
//         <Modal.Body>
//           <form onSubmit={bulkuploadhandle}>
//         <label htmlFor='fileFrom'>Choose excel file to upload bulk data</label>
// <input type="file" id='fileFrom' onChange={(e) => setBfile(e.target.files[0])} />

//         <div className='col-12'>
//         <Button type='submit' style={{marginBottom:30}} >Upload</Button>
//       </div>
//       </form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseBulk}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal> */}
  
//   <Modal show={isOpen1} onHide={handleCloseBulk} size='md'>
//       <Modal.Header closeButton>
//         <br /><br /><br /><br /><br />
//       </Modal.Header>
//       <Modal.Body>
//         <form onSubmit={bulkuploadhandle}>
//           <label htmlFor='fileFrom'>Choose excel file to upload bulk data</label>
//           <input type="file" id='fileFrom' onChange={handleFileChange} />

//           <div className='col-12'>
//             <Button type='submit' style={{ marginBottom: 30 }}>Upload</Button>
//           </div>
//         </form>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleCloseBulk}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
       
      
     
//       <div style={{ marginTop: '1rem' }} className='table-container'>
//         <table className="styled-table table  table-striped table-responsive table-bordered">
//           <thead>
//             <tr>
//               <th>S.No</th>
//               <th>Membership_Number</th>
//               <th>Membership_Name</th>
//               <th>Club_Name</th>
//               <th>Profile</th>
//               <th>Designation</th>
//               <th>Profession</th>
//               <th>Date of birth</th>
//               <th>Marriage_Anniversary</th>
//               <th>Blood_Group</th>
//               <th>Address</th>
//               <th>Mobile</th>
//               <th>Email</th>
//               <th>Spouse</th>
//               <th>Update</th>
//               <th>Delete</th>
//             </tr>
//           </thead>
//           <tbody className='table-bordered'>
//             {
//               member.slice(pagination.start, pagination.end).map((data, index) => {

//                 let birth = ''
//                 let marriage = ''

//                 if (!moment(data.birthAnniversary).year()) {
//                   birth = ''
//                 } else {
//                   birth = `${moment(data.birthAnniversary).date()}/${moment(data.birthAnniversary).month() + 1}/${moment(data.birthAnniversary).year()}`
//                 }

//                 if (!moment(data.marriageAnniversary).year()) {
//                   marriage = ''
//                 } else {
//                   marriage = `${moment(data.marriageAnniversary).date()}/${moment(data.marriageAnniversary).month() + 1}/${moment(data.marriageAnniversary).year()}`
//                 }

//                 return (
//                   <tr>
//                     <td>{index + 1}</td>
//                     <td>{data.membershipNo}</td>
//                     <td>{data.membershipName}</td>
//                     <td>{data.clubs}</td>
//                     <img src={`https://lions320dapi.emedha.in/images/${data.photo}`} height='80px' width='80px' />
//                     <td>{data.designation}</td>
//                     <td>{data.profession}</td>
//                     <td>{birth}</td>
//                     <td>{marriage}</td>
//                     <td>{data.bloodGroup}</td>
//                     <td>{data.address}</td>
//                     <td>{data.mobile}</td>
//                     <td>{data.email}</td>
//                     <td>{data.spouseName}</td>
//                     <td className=''>
//                       <Link to={`/update/${data.memberId}`} className='btn btn-info text-white'>update</Link>
//                     </td>
//                     <td className=''>
//                       <Link to='#' className='btn btn-danger' onClick={() => deleteData(data.membershipNo)}>delete</Link>
//                     </td>
//                   </tr>
//                 )
//               })
//             }
//           </tbody>
//         </table>
//         <Pagination page={showPerPage} onChangePagination={onChangePagination} total={member.length} />
//       </div>


//     </>
//   )
// }

// export default Member

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Form, Link } from 'react-router-dom'
import moment from 'moment'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Container } from '@mui/system'
import { FaSearch } from 'react-icons/fa';
import { MDBBtn, MDBCollapse, MDBContainer, MDBFile, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit'
import Swal from 'sweetalert2';
import './index.css'



const Member = () => {

  const [member, setMember] = useState([])
  const [showPerPage] = useState(5)
  const [pagination, setPagination] = useState(
    {
      start: 0,
      end: showPerPage
    }
  )

  const onChangePagination = (start, end) => {
    setPagination({ start: start, end: end })
  }

  const memberData = async () => {
    const res = await axios.get(`https://lions320dapi.emedha.in/memberMasteData`)
    setMember(res.data)
    console.log(res.data)
  }

  const [memberClubName, setMemberClubName] = useState([])

  const memberDataClubName = async () => {
    const res = await axios.get(`https://lions320dapi.emedha.in/clubData`)
    setMemberClubName(res.data)
  }

  useEffect(() => {
    memberData()
    memberDataClubName()
  }, [])

  const [number, setMemberNumber] = useState('')
  const [memberName, setMemberName] = useState('')
  const [clubName, setClubName] = useState('')
  const [designation, setDesignation] = useState('')
  const [profession, setProfession] = useState('')
  const [photo, setPhoto] = useState('')
  const [birth, setBirth] = useState('')
  const [marriage, setMarriage] = useState('')
  const [blood, setBlood] = useState('')
  const [address, setAddress] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [spouseName, setSpouse] = useState('')
  const [myMessage, setMessage] = useState('')
  const [search, setSearch] = useState()


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseBulk=()=>setIsOpen1(false)

  // https://lions320dapi.emedha.in/memberCreate

  // const submitData = (e) => {
  //   e.preventDefault();
  //   axios.post('http://localhost:8002/memberCreate', {
  //     number,
  //     memberName,
  //     clubName,
  //     designation,
  //     profession,
  //     photo,
  //     birth,
  //     marriage,
  //     blood,
  //     address,
  //     mobile,
  //     email,
  //     spouseName
  //   }).then(() => {
  //     alert('Data Successfully Inserted!')
  //     setShow(false)
  //     setTimeout(() => memberData(), 100)
  //   }).catch((err) => alert(err.res.data))

  // }

  const submitData = (e) => {
    e.preventDefault();

    // Create a new FormData instance
    const formData = new FormData();
    
    // Append all form fields to FormData
    formData.append('number', number);
    formData.append('memberName', memberName);
    formData.append('clubName', clubName);
    formData.append('designation', designation);
    formData.append('profession', profession);
    formData.append('birth', birth);
    formData.append('marriage', marriage);
    formData.append('blood', blood);
    formData.append('address', address);
    formData.append('mobile', mobile);
    formData.append('email', email);
    formData.append('spouseName', spouseName);
    
    // Append the file if it exists
    if (photo) {
        formData.append('photo', photo);
    }

    // Send the request
    axios.post('https://lions320dapi.emedha.in/memberCreate', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(() => {
        alert('Data Successfully Inserted!');
        setShow(false);
        setTimeout(() => memberData(), 100);
    })
    .catch((err) => alert('Error: ' + err.response.data));
}



  const deleteData = (id) => {
    if (window.confirm('Are you sure to delete this data...')) {
      axios.delete(`https://lions320dapi.emedha.in/deleteMember/${id}`)
      alert('Data deleted successfully!...')
      setTimeout(() => memberData(), 100)
    }
  }

  const searchHandler = (e) => {
    setSearch(e.target.value)

    axios.post('https://lions320dapi.emedha.in/search', {
      search
    }).then((resp) => {
      setMember(resp.data)
    })
  }

  // const[bfile,setBfile] = useState('')
  // const bulkuploadhandle = (e) => {
  //   e.preventDefault()
  //   const data = new FormData()
  //   data.append("excel", bfile)
  //   axios.post('http://localhost:3012/addmemberbulk',data)
  //   .then((res) => {
  //     if (res.data.status === 200) {
  //         toggleOpen1();
  //         Swal.fire({
  //             icon: 'success',
  //             title: 'Success',
  //             text: 'Data submitted successfully!',
  //         }).then(() => {
  //           window.location.reload();
  //       });
  //     }
  // })
  // .catch((error) => {
  //     console.error('Error:', error);
  // });
  // }


  const [isOpen1, setIsOpen1] = React.useState(false);
  const toggleOpen1 = () => setIsOpen1(!isOpen1);
  const [bfile, setBfile] = useState(null);


    const handleFileChange = (e) => {
      console.log('Selected File:', e.target.files[0]);
      setBfile(e.target.files[0]);
    };
    


  const bulkuploadhandle = async (e) => {
    e.preventDefault(); // Prevent default form submission

    if (!bfile) {
      Swal.fire({
        icon: 'error',
        title: 'File Required',
        text: 'Please select a file to upload.',
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('excel', bfile);

      const response = await axios.post('https://lions320dapi.emedha.in/addmemberbulk', formData);

      if (response.data.status === 200) {
        toggleOpen1();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Data submitted successfully!',
          customClass: {
            confirmButton: 'custom-swal-button',
          }
        }).then(() => {
          window.location.reload(); // Reload the page after success
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  };

  return (

    <>
      <Container><h5 className='text-center text-danger'>{myMessage}</h5></Container>
      <Button variant="primary" onClick={handleShow}>
        Add<PersonAddAltIcon></PersonAddAltIcon>
      </Button>
      <Button rounded className='mx-3' color='secondary'  onClick={toggleOpen1}>
        Bulk upload
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <br /><br /><br /><br /><br />
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitData}>
            <input type='number' className='form-control' placeholder='Membership Number' required onChange={(e) => setMemberNumber(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Membership Name' required onChange={(e) => setMemberName(e.target.value)} />
            <br />
            <select type='text' className='form-control' placeholder='Designation' onChange={(e) => setClubName(e.target.value)}>
              <option>Select Club Name</option>
              {
                memberClubName.map((data) => {
                  return (
                    <option value={data.clubName}>
                      {data.clubName}
                    </option>
                  )
                })
              }
            </select>
            <br />
            <input type='text' className='form-control' placeholder='Designation' required onChange={(e) => setDesignation(e.target.value)} />
            <br />

            <input type='text' className='form-control' placeholder='Mobile' onChange={(e) => setMobile(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Spouse' onChange={(e) => setSpouse(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Profession' onChange={(e) => setProfession(e.target.value)} />
            <br />
            <input type='file' className='form-control' placeholder='choose file...' onChange={(e) => setPhoto(e.target.files[0])} />

            <br />
            <input type='date' className='form-control' placeholder='Birth Anniversary' onChange={(e) => setBirth(e.target.value)} />
            <br />
            <input type='date' className='form-control' placeholder='Marriage Anniversary' onChange={(e) => setMarriage(e.target.value)} />
            <br />

            <input type='text' className='form-control' placeholder='Blood Group' onChange={(e) => setBlood(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Address' onChange={(e) => setAddress(e.target.value)} />
            <br />
            <button type='submit' className='btn btn-danger form-control'>Submit</button>
            <br />
            <br />
            <button type='reset' className='btn btn-secondary form-control'>Reset</button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <FaSearch className='searchIcon'></FaSearch><input type='search' className='ms-4 search-input-field' placeholder='Search..' onChange={searchHandler} />
     
      

{/* <Modal show={isOpen1} onHide={handleCloseBulk} size='md'>
        <Modal.Header closeButton>
          <br /><br /><br /><br /><br />
          
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={bulkuploadhandle}>
        <label htmlFor='fileFrom'>Choose excel file to upload bulk data</label>
<input type="file" id='fileFrom' onChange={(e) => setBfile(e.target.files[0])} />

        <div className='col-12'>
        <Button type='submit' style={{marginBottom:30}} >Upload</Button>
      </div>
      </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBulk}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
  
  <Modal show={isOpen1} onHide={handleCloseBulk} size='md'>
      <Modal.Header closeButton>
        <br /><br /><br /><br /><br />
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={bulkuploadhandle}>
          <label htmlFor='fileFrom'>Choose excel file to upload bulk data</label>
          <input type="file" id='fileFrom' onChange={handleFileChange} />

          <div className='col-12'>
            <Button type='submit' style={{ marginBottom: 30 }}>Upload</Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseBulk}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
       
      
     
      <div style={{ marginTop: '1rem' }} className='table-container'>
        <table className="styled-table table  table-striped table-responsive table-bordered">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Membership_Number</th>
              <th>Membership_Name</th>
              <th>Club_Name</th>
              <th>Profile</th>
              <th>Designation</th>
              <th>Profession</th>
              <th>Date of birth</th>
              <th>Marriage_Anniversary</th>
              <th>Blood_Group</th>
              <th>Address</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>Spouse</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody className='table-bordered'>
            {
              member.slice(pagination.start, pagination.end).map((data, index) => {

                let birth = ''
                let marriage = ''

                if (!moment(data.birthAnniversary).year()) {
                  birth = ''
                } else {
                  birth = `${moment(data.birthAnniversary).date()}/${moment(data.birthAnniversary).month() + 1}/${moment(data.birthAnniversary).year()}`
                }

                if (!moment(data.marriageAnniversary).year()) {
                  marriage = ''
                } else {
                  marriage = `${moment(data.marriageAnniversary).date()}/${moment(data.marriageAnniversary).month() + 1}/${moment(data.marriageAnniversary).year()}`
                }

                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data.membershipNo}</td>
                    <td>{data.membershipName}</td>
                    <td>{data.clubs}</td>
                    <img src={`${data.photo}`} height='80px' width='80px' />
                    <td>{data.designation}</td>
                    <td>{data.profession}</td>
                    <td>{birth}</td>
                    <td>{marriage}</td>
                    <td>{data.bloodGroup}</td>
                    <td>{data.address}</td>
                    <td>{data.mobile}</td>
                    <td>{data.email}</td>
                    <td>{data.spouseName}</td>
                    <td className=''>
                      <Link to={`/update/${data.memberId}`} className='btn btn-info text-white'>update</Link>
                    </td>
                    <td className=''>
                      <Link to='#' className='btn btn-danger' onClick={() => deleteData(data.membershipNo)}>delete</Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Pagination page={showPerPage} onChangePagination={onChangePagination} total={member.length} />
      </div>


    </>
  )
}

export default Member