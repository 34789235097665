import React, { useState,useEffect } from 'react'
import Pagination from '../pagination/Pagination';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

// Import Material Icons
 


const Dollar = () => {

    const [dMonth,setDMonth]=useState('')
    const [dRate,setDRate]=useState('')
    const [dYear,setDYear]=useState('')
  
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [Dollar,setDollar]=useState([])
    const[showPerPage,setShowPerPage]=useState(10)
    const [pagination,setPagination]=useState(
      {
        start:0,
        end:showPerPage
      }
    )
   
    const onChangePagination=(start,end)=>{
      setPagination({start:start,end:end})
    }
  
    const DollarData= async ()=>{
      const res=await axios.get(`https://lions320dapi.emedha.in/dollar/get`,{
      })
      setDollar(res.data)
    }

    const DollarDataPost= (e)=>{

      e.preventDefault();

      axios.post(`https://lions320dapi.emedha.in/dollar/post`,{
        dRate,
        dMonth,
        dYear
      }).then((res)=>{
        alert(res.data)
        DollarData()
        setShow(false)
      })
       
    }
  
    useEffect(() => {
      DollarData()
    }, []);
    
    
    const deleteData=(id)=>{
      if(window.confirm('Are you sure to delete this data...')){
        axios.post(`https://lions320dapi.emedha.in/dollar/delete`,{
          id
        })
        alert('Data deleted successfully!...')
        setTimeout(()=>DollarData(),100)
        DollarData()
      }
    }
  
    const search=()=>{
      
    }
  


  
return (


<>
      <Button variant="warning" onClick={handleShow}>
         Add<PersonAddAltIcon></PersonAddAltIcon>
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={DollarDataPost}>

           <input type='text'     className='form-control' placeholder='dollar rate' onChange={(e)=>setDRate(e.target.value)} required  />
           <br/>
           <input type='text'    className='form-control' placeholder='dollar month'  onChange={(e)=>setDMonth(e.target.value)} />
           <br/>
           <input type='text'    className='form-control' placeholder='dollar year' onChange={(e)=>setDYear(e.target.value)}  />
           <br/>
           <button type='submit' className='btn btn-danger form-control'>Submit</button>
           <br/>
           <br/>
           <button type='reset' className='btn btn-secondary form-control'>Reset</button>

         </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
  </Modal>
  <div style={{marginTop:'1rem'}} className='table-container'>
       <table className="styled-table table table-bordered">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Dollar Month</th>
            <th>Dollar Rate</th>
            <th>Dollar Year</th>
            <th>Delete</th>
          </tr>
          
        </thead>
        <tbody className='table-bordered'>
          {

            Dollar.map((data,index)=>{
              return(
                <tr>
                  <td>{index+1}</td>

                  <td>{data.dmonth}</td>
                  <td>{data.drate}</td>
                  <td>{data.dyear}</td>
                   
                  <td className=''>
                    <Link to='#' className='btn btn-danger' onClick={()=>deleteData(data.sno)}>delete</Link>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
       </table>
      <Pagination page={showPerPage} onChangePagination={onChangePagination} total={Dollar.length}/>      
  </div>
</>
  )
}

export default Dollar